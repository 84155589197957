/* recipe styles */
.recipes{
    margin-top: 20px;
}
.card-panel.recipe{
    border-radius: 8px;
    padding: 10px;
    box-shadow: 0px 1px 3px rgba(90,90,90,0.1);
    display: grid;
    grid-template-columns: 2fr 6fr 1fr;
    grid-template-areas: "image details price";
    position: relative;
}
.recipe img{
    grid-area: image;
    max-width: 60px;
}
.recipe-details{
    grid-area: details;
    margin-top: 6px;
}
.recipe-price{
    grid-area: price;
    margin-top: 6px;
}
.recipe-delete i{
    font-size: 18px;
}
.recipe-title{
    font-weight: bold;
}
.recipe-ingredients{
    font-size: 0.8em;
}